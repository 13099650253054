.tasks-due-today {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: rgb(66, 34, 154);
  border-radius: 0 0 8px 8px;
  padding: 24px 30px;
  margin-bottom: 24px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.due-task-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  a {
    color: #ffffff;
    &:hover {
      text-decoration: none;
    }
  }

  .task-completion-toggle .circle {
    width: 24px;
    height: 24px;
  }
}

.chevron-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  color: white;
  padding: 12px 0;
  cursor: pointer;

  & > * {
    color: white !important;
  }
}
