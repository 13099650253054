$dropdownWidth: 344px;


.date-range-dropdown {
  position: relative;
  width: $dropdownWidth;
  cursor: pointer;

  .active-option,
  .option {
    display: flex;
    justify-content: space-between;
    padding: 8px 12px;

    .label {
      font-size: 14px;
      color: #2b2e31;
    }

    .date {
      font-size: 12px;
      color: #86919a;
    }
  }

  .option {
    &:hover {
      background-color: #f8f8f8;
    }
  }

  .dropdown-top {
    padding-right: 18px;
    border-radius: 4px;
    border: solid 1px rgba(64, 64, 66, 0.24);
    background-color: #ffffff;

    &.opened {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background-color: #f8f8f8;
      box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15);
    }

    .arrow {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-25%);
      border: 5px solid transparent;
      border-top-color: #b1b1b1;

      &.opened {
        transform: rotate(180deg) translateY(75%);
      }
    }
  }

  .dropdown-body {
    position: absolute;
    top: 100%;
    width: $dropdownWidth;
    max-height: 270px;
    overflow-y: scroll;
    border: solid 1px rgba(64, 64, 66, 0.24);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: none;
    background: white;
    z-index: 1000;
    box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.15);

    .custom {
      display: flex;
      align-items: center;
      padding: 8px 12px 12px;
      font-size: 14px;
      font-weight: 600;
      color: #2b2e31;
      border-top: 1px solid rgba(64, 64, 66, 0.24);
      background-color: #f8f8f8;

      svg {
        margin-right: 11px;
      }
    }
  }
}
