@import '../../../SCSS/var';

#header {
  max-height: 70px;
  padding-top: 7px;
  padding-bottom: 7px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.16);
  background-color: #f8f8f7;
  position: relative;
  z-index: 14;

  .logo-img {
    img {
      width: 98px;
      height: 37px;
    }
  }

  .upgrade-btn-container {
    margin-left: 60px;
  }

  .row-1 {
    color: $gray_1;
    font-size: 15px;
    line-height: 24px;

    .navbar {
      text-align: right;
      padding: 10px 30px;
    }
  }

  &.dark {
    color: $white_1;

    .row-1 {
      color: $white_1;

      a.nav-link {
        color: $white_1;
      }
    }
  }
}

@media screen and (max-width: $responsive_mw_sm) {
  #header {

    .logo-img {
      img {
        height: 24px;
        width: 24px;
      }
    }

    .row-1 {
      .navbar {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .upgrade-btn-container {
      display: none;
    }
  }
}
