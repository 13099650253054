@import '../../../../SCSS/_var.scss';

.member-results {
  padding: 34px;

  .buttons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    .checkin-controls {
      display: flex;
      flex-wrap: nowrap;

      .control-button {
        margin-right: 8px;
        background-color: #f8f8f8;
        border: solid 1px rgba(64, 64, 66, 0.24);

        svg {
          stroke: #0f2136
        }

        .control-button-label {
          color: #0f2136
        }
      }
    }

    .save-share-button {
      height: 32px;
    }

    .timeline-controls {
      display: flex;
      width: 900px;
      padding: 0 30px;

      & > * {
        margin-right: 16px;
      }

      .generate-report-btn {
        margin: auto;
      }
    }
  }

  .charts-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 15px;

    .wheel-container {
      margin-left: 30px;
      margin-right: 64px;
    }
  }
}

@media only screen and (max-device-width: 1281px) {
  .member-results {
    .buttons-container {
      .timeline-controls {
        width: 850px;
      }
    }
  }
}

// phones
@media only screen and (max-device-width: $responsive_mw_md) {
  .member-results {
    padding: 24px 10px;

    .buttons-container {
      flex-direction: column-reverse;
      align-items: flex-start;

      .checkin-controls {
        margin-top: 16px;
      }

      .save-share-button {
        margin-top: 4px;
      }

      .timeline-controls {
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 0;

        & > * {
          margin-right: 0;
          margin-bottom: 16px;
        }

        .member-dropdown {
          width: 344px;

          .dropdown-body {
            width: 344px;
          }
        }

        .generate-report-btn {
          align-self: flex-start;
        }

        .lock-feature-wrapper {
          margin-top: 5px;
          .lock-feature-icon {
            top: -14px;
            right: -10px;
          }
        }
      }
    }

    .charts-container {
      flex-direction: column;
      align-items: flex-start;

      .wheel-container {
        margin: 0;
        align-self: center;
      }

      .history-chart-container {
        margin-top: 20px;
        width: 100%;
        overflow-x: scroll;
      }
    }
  }
}
