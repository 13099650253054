.wheel-label {
  display: inline-block;
  padding: 3px 8px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.22);
  font-size: 12px;
  line-height: 1.5;
  color: #ffffff;

  .wheel-label-icon {
    margin-right: 4px;
  }
}
