@import '../../../SCSS/var';

$tabContainerHeight: 615px;
$tabHeaderHeight: 56px;

.tab-container {
  min-height: $tabContainerHeight;
  border-radius: 4px;
  background: #ffffff;

  .tab-header {
    box-shadow: 0 2px 10px 0 rgba(12, 35, 55, 0.15);
    display: flex;
    flex-direction: row;

    .arrow {
      font-size: 18px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-self: center;
      cursor: pointer;
    }

    .tabs-wrapper {
      display: flex;
      justify-content: flex-start;
      position: relative;
      overflow-x: auto;
      overflow-y: hidden;
      &::-webkit-scrollbar {
        display: none;
      }

      .tab {
        padding: 20px 30px 10px;
        cursor: pointer;
        position: relative;

        span {
          white-space: nowrap;
          font-size: 18px;
          font-weight: 500;
          line-height: 1.3;
          color: $dark_grey;
          user-select: none;
        }

        &.active {
          border-bottom: 3px solid $coral_red;
          span {
            color: $coral_red;
          }
        }
      }

      .lock-feature-icon {
        top: 10px;
        right: 10px;
      }
    }

    .line-wrapper {
      position: relative;
      height: 3px;

      .line {
        display: inline-block;
        position: absolute;
        height: 3px;
        top: 0;
        left: 0;
        will-change: transform;
        transition: width, transform 0.5s;
        background-color: $coral_red;
      }
    }
  }

  .tab-body {
    position: relative;
    min-height: $tabContainerHeight - $tabHeaderHeight;

    .spinner-backing {
      display: flex;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      justify-content: center;
      z-index: 1000;

      .spinner {
        top: 50vh;
      }
    }
  }
}

// phones
@media only screen and (max-device-width: $responsive_mw_md) {
  .tab-container {
    .tab-header {
      @include hide-scroll-bar;
      overflow-x: scroll;

      .arrow {
        width: 64px;
      }

      .tabs-wrapper {
        .tab {
          span {
            font-size: 16px;
          }
        }
      }
    }
  }
}
