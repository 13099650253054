.task-item-box {
  display: flex;
  flex-direction: row;
}

.task-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 24px 16px;
  border: 1px solid #eaeaea;
  background: #f8f8f8;
  border-radius: 8px;
  margin-bottom: 8px;
  transition: background-color 0.2s ease;

  &:hover {
    background: rgb(254, 250, 242);
  }

  .task-item-group {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .task-details {
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  .task-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    svg {
      cursor: pointer;
    }
  }
}

.pills {
  display: flex;
  gap: 6px;

  .pill-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 8px 4px;
    gap: 5px;
    cursor: context-menu;

    .option {
      display: flex;
      justify-content: fle;
      align-items: center;
      padding: 0 16px;
      height: 40px;
    }
  }

  .pill {
    position: relative;
    background-color: #0f2136;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
    height: 24px;
    color: #ffffff;

    &:hover {
      background-color: #2e485e;
    }
  }

  .pill-edit {
    position: relative;
    padding: 2px 6px 0;
    background-color: #f8f8f8;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    height: 32px;
    color: #0c2337;
    border: 1px solid #cececf;
    cursor: pointer;

    .pill-group {
      gap: 8px;
    }

    &:hover {
      background-color: #2a3e50;
      color: #ffffff;
    }

    .initials {
      margin-right: 10px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      font-size: 9px;
      text-align: center;
      line-height: 20px;
      color: white;
      background: black;
    }
  }

  .popup {
    position: absolute;
    color: #2b2e31;
    top: 30px;
    padding-top: 6px;
    left: 0;
    z-index: 10;

    &-body {
      background-color: white;
      border: 1px solid #e0e0e0;
      border-radius: 8px;
      white-space: nowrap;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      padding: 4px 0;
      max-height: 350px;
      overflow-y: auto;
    }

    &-body:not(:has(:first-child))::-webkit-scrollbar {
      display: none;
    }

    & > input {
      width: 100%;
      padding: 4px;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
    }

    .option {
      font-size: 14px;
      cursor: pointer;
      padding: 6px 10px;
      border-radius: 6px;

      &:hover {
        background-color: #f8f9fa;
      }
    }

    .MuiPickersDay-root.Mui-selected {
      background-color: #fb463b;
    }
  }
}

.date-picker-gap {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border: 1px solid rgba(64, 64, 66, 0.24);
  border-radius: 4px;
  background: white;
  padding: 2px 12px;
  min-width: 100px;
  cursor: pointer;

  .MuiBox-root {
    margin-left: 0 !important;
    cursor: context-menu;
  }
  .MuiInputBase-input {
    cursor: pointer;
    color: #2b2e31 !important;
    font-size: 16px !important;
    padding: 6px 0px 4px !important;
  }
}

.custom-popover {
  transform: translateY(-72px) !important;

  .MuiPaper-root {
    .MuiButtonBase-root {
      max-height: 24.5px;
    }
  }
}

.task-completion-toggle {
  .circle {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &-history {
      cursor: context-menu;
    }

    &.default {
      border: 2px solid #ccc;
      background: #fff;

      &-loading {
        border: 2px solid green;
        border-bottom: 2px solid #ccc;
        animation: snake-animation 1.5s linear infinite;
      }

      @keyframes snake-animation {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    &.complete {
      background: linear-gradient(135deg, rgb(28, 143, 143), rgb(49, 213, 116));
    }

    &.incomplete {
      background: rgb(42, 62, 80);
    }
  }
}

.task-checkbox-group {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.task-title-complete {
  text-decoration: line-through;
  color: #6c757d;
}

.task-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.no-task,
.no-task-no-img {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-task-no-img {
  margin-bottom: 40px;
}

.no-task-img {
  max-width: 600px;
  width: 50%;
}

.assignee-popup {
  position: absolute;
  bottom: 30px;
  left: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: min-content;
}

.assignee-item {
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid rgba(64, 64, 66, 0.24);
  border-radius: 16px;
  width: min-content;
  padding: 3px 6px;

  span {
    white-space: nowrap;
  }
}

.hover-circle,
.hover-circle-initials {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  font-weight: 300;
  background: linear-gradient(135deg, rgb(28, 143, 143), rgb(49, 213, 116));
  color: white;
}

.hover-circle-initials {
  background: #0f2136;
}

.badges-history {
  margin-top: 4px;
  padding-left: 44px;
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.custom-input {
  cursor: pointer !important;
  &:hover {
    background-color: transparent !important;
    border: none !important;
  }

  &::before,
  &::after {
    border: none !important;
  }
}

.switch-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.assignee,
.delivery-method {
  label {
    margin-bottom: 0;
  }

  .MuiFormControlLabel-root {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    justify-content: flex-start;
  }

  .MuiTypography-body1 {
    width: -webkit-fill-available;
  }

  .MuiSwitch-track {
    background-color: rgb(248, 248, 248);
    border: 1px solid rgb(205, 212, 218);
  }

  .MuiSwitch-thumb {
    background-color: rgb(206, 206, 207);
  }

  .Mui-checked > .MuiSwitch-thumb {
    display: block;
    background: linear-gradient(135deg, rgb(28, 239, 239), rgb(0, 155, 140));
  }

  .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: rgba(2, 162, 149, 0.19);
  }
}
